import React from "react";
import PropTypes from "prop-types";
// import { graphql, useStaticQuery } from "gatsby";
import { Container, MasterTitle, Title, P, A } from "../../common/info/style";

const Info = props => {
  const scrollToAnchor = anchorName => {
    if (anchorName) {
      if (typeof window !== `undefined`) {
        let anchorElement = document.getElementById(anchorName);
        if (anchorElement) {
          anchorElement.scrollIntoView(100);
        }
      }
    }
  };
  if (props.position) {
    scrollToAnchor("pos" + props.position);
  }

  return (
    <Container>
      <A id="pos1"></A>
      {/** product*/}
      <A id="pos1"></A>
      <A id="pos2"></A>
      <MasterTitle>APP高级产品经理</MasterTitle>
      <Title>岗位职责:</Title>
      <P>1、 参与非洲支付业务APP的产品规划，结合公司发展战略，明晰目标、策略并落实实施；</P>
      <P>
        2、把握出海Fintech发展趋势，关注欧美、非洲支付产品业务数据演进，分析潜在竞争对手，挖掘非洲用户支付需求及习惯；
      </P>
      <P>3、与技术人员一起，完成各项产品策略落地和优化；</P>
      <P>4、针对用户数据、行为数据分析并找到对应的提升方案，为产品优化提供依据；</P>
      <P>5、为所负责产品的用户体验及用户增长负责，主动驱动业务达成增长目标；</P>
      <Title>职位要求:</Title>
      <P>1、5年或以上产品策划经验；</P>
      <P>2、有成功的互联网产品案例优先； 有海外产品经验优先；</P>
      <P>3、有参与产品前期分析及市场定位的工作经验优先，能够独立完成产品功能及数据效果分析并提出相应的产品优化建议；</P>
      <P>4、具有较强的责任心、团队协作能力和创新精神，有策略性眼光；</P>
      <P>5、逻辑清晰，思路开阔，具备较强的总结归纳能力。 </P>

      <A id="pos3"></A>
      <MasterTitle>支付产品经理（生活缴费业务）</MasterTitle>
      <Title>岗位职责:</Title>
      <P>
        1、负责APP端账单缴费产品交互功能需求，负责OPay钱包APP-海外账单缴费场景及支付相关产品的独立迭代，对用户体验负责；
      </P>
      <P>2、持续对缴费产品数据进行分析，持续接收用户真实反馈，制定产品迭代计划、不断完善用户体验；</P>
      <P>3、挖掘缴费业务订单量的增涨空间，探索当地不同的缴费场景、不同用户场景的精细化产品价值提升。</P>
      <Title>职位要求:</Title>
      <P>1、本科及以上学历，3年以上支付产品工作经验；</P>
      <P>2、对C端用户体验极度敏感，能够敏锐把握体验细节；</P>
      <P>3、主导或参与过移动端产品设计，熟悉移动互联网产品和服务，有支付产品经验者优先；</P>
      <P>4、有较强的沟通能力、协调能力和推进能力，结果导向，具备优秀的数据分析能力。</P>

      <A id="pos4"></A>
      <MasterTitle>产品运营（生活缴费业务）</MasterTitle>
      <Title>岗位职责:</Title>
      <P>
        1、负责账单缴费产品效率提升，承担缴费产品的日常运营和维护工作，包括业务交易、账务、通道等相关异常沟通与跟进；
      </P>
      <P>2、负责缴费交易场景的前期对接，确认业务背景和商业价值，制定符合业务需求的收付款方案；</P>
      <P>3、负责产品上线后的业务价值传递、业务监控、数据分析、搜集和整理客户反馈；</P>
      <P>4、负责竞品分析，收集和分析业内相关产品，为产品规划提供支持。</P>
      <Title>职位要求:</Title>
      <P>1、本科及以上学历，2年以上产品运营工作经验；</P>
      <P>2、良好的团队沟通协调能力,较强的学习能力和领悟能力，具有责任心和一定的抗压能力；</P>
      <P>3、有支付平台产品或运营工作经验者优先考虑；</P>
      <P>4、英文水平良好，能作为工作语言者优先。</P>

      <A id="pos5"></A>
      <MasterTitle>客服系统产品经理</MasterTitle>
      <Title>岗位职责:</Title>
      <P>1、负责客服系统规划、需求挖掘、功能设计及相关项目推动工作；</P>
      <P>
        2、深入了解客服和业务需求，以提升客服体验和效率为目标，发现流程和产品中可改善的点，通过产品化、工具化形式赋能客服；
      </P>
      <P>3、保证产品高质量上线，并做好上线后的效果评估，以及后续迭代优化工作；</P>
      <Title>职位要求:</Title>
      <P>1、3年以上客服系统或CRM系统相关经验；</P>
      <P>2、具备良好的沟通能力、逻辑思维和数据分析能力，能基于复杂的业务场景，独立进行规划设计；</P>
      <P>3、有团队管理经验。</P>

      <A id="pos6"></A>
      <MasterTitle>Local Shop高级产品经理</MasterTitle>
      <Title>岗位职责:</Title>
      <P>1、负责OPay Localshopping业务线（对标美团、点评）的产品设计工作，为用户提供良好的使用体验；</P>
      <P>2、参与平台商家营销玩法的设计，结合用户权益，建立匹配的产品形态，提升交易用户数和单量；</P>
      <P>3、与上下游和横向产品团队紧密协作，为用户转化留存建立长期的产品能力和运营机制；</P>
      <P>4、了解行业，定期调研海外用户和竞品，掌握行业及竞争对手动态及发展趋势，根据市场变化迭代产品策略。</P>
      <Title>职位要求:</Title>
      <P>1、3年以上互联网产品经验，1年以上电商/O2O用户端产品经验。有外卖、出海产品从业经验优先；</P>
      <P>2、能够基于业务目标拆解项目，具备较强的用户需求洞察能力和较好的审美，对产品体验精益求精；</P>
      <P>3、具备较强的逻辑思维和数据分析能力，能够独立思考，深入挖掘问题本质，能够不断积累经验和复盘；</P>
      <P>4、独立负责过大型项目或跨团队项目，具备良好的执行力和沟通协作能力；</P>
      <P>5、英语沟通流畅优先。</P>

      <A id="pos7"></A>
      <A id="pos8"></A>
      <MasterTitle>风控模型工程师（信用支付业务）</MasterTitle>
      <Title>岗位职责:</Title>
      <P>
        1、负责OPay信用支付项目反欺诈风控模型的研发、迭代及优化工作，包括模型需求分析，模型设计，模型开发，模型评审，效果追踪等；
      </P>
      <P>2、负责根据用户、交易等特征结合产品，制定事前、事中、事后风险模型，并对实施的风险模型进行监控、评估和优化；</P>
      <P>3、根据业务需求，建设不同应用场景的机器学习模型，完成模型融合，完成机器学习模型部署；</P>
      <P>4、从非结构化数据中挖掘出个体以及网络群体的特征，不断优化和完善图特征，为反欺诈模型及策略提供有效支持。</P>
      <Title>职位要求:</Title>
      <P>1、本科及以上学历，数学、统计、计算机等专业；</P>
      <P>2、两年以上数据挖掘或建模经验，有电商、支付、互金行业背景优先；</P>
      <P>3、熟悉GBDT、XGBOOST、随机森林、RNN等机器学习算法；</P>
      <P>4、精通支付、信贷风控领域特征工程方法论，并有独到见解 ；</P>
      <P>5、精通SQL、Python、R、SAS等工具。</P>

      <A id="pos9"></A>
      <MasterTitle>支付账户开发专家</MasterTitle>
      <Title>岗位职责:</Title>
      <P>1、1、 支持OPay支付平台账户体系的建设，账户的业务系统和技术产品的研发与维护；</P>
      <P>2、参与建设通用、灵活、智能的账户平台，支撑多场景的复杂业务；</P>
      <P>3、负责相关产品的系统分析、设计，并独立完成详细设计和编码的任务，确保项目的进度和质量。</P>
      <Title>职位要求:</Title>
      <P>1、 五年以上软件开发工作经验，熟练使用git版本控制系统，以及linux命令；</P>
      <P>2、扎实的JAVA语言基础，具有良好的编码习惯，具备DDD相关经验者优先</P>
      <P>3、熟悉Mysql 等关系型数据库、以及Elasticsearch、Redis 等非关系型数据库；</P>
      <P>4、熟练掌握微服务技术栈，包括SpringBoot、SpringCloud、RPC框架等；</P>
      <P>5、熟悉RocketMq、 Kafaka等消息中间件；</P>
      <P>6、扎实的后端基础：数据结构、多线程、内存、JDK；</P>
      <P>7、熟悉银行支付清结算体系结构、账户账务、对账 、资金流、支付金融相关设计研发工作。</P>

      <A id="pos10"></A>
      <MasterTitle>高级支付开发（账户/生活缴费业务）</MasterTitle>
      <Title>岗位职责:</Title>
      <P>1、 负责所属模块的发展建设，包含功能的规划、需求分析设计与技术实现；</P>
      <P>2、完成核心/重要模块的设计、编码、测试以及相关文档；</P>
      <P>3、参与代码设计、审核和检查；</P>
      <P>4、帮助团队解决开发中遇到的难点问题，负责技术攻坚工作；</P>
      <P>5、1、 参与系统稳定性、性能和扩展性调试。</P>
      <Title>职位要求:</Title>
      <P>1、 本科以上学历，5年以上JAVA软件开发经验；</P>
      <P>2、扎实的JAVA编程基础，熟悉算法、数据结构、多线程编程、网络编程等；</P>
      <P>3、熟悉主流框架（Spring、Hibernate、Mybatis、WebMVC），熟练使用jQuery、EXtJS、css等。</P>
      <P>4、熟练掌握数据库sybase、SQLServer、MongoDb的使用，熟悉Linux、Tomcat、memcache、SVN等；</P>
      <P>5、熟悉远程服务调用技术，如RMI、Hessian、WebServices等；</P>
      <P>6、对数据库分库、分表设计和优化有很好的实战经验，熟悉Tomcat集群，Nginx在Linux下的配置和调优；</P>
      <P>7、有大规模并发系统的开发经验优先，有金融、电子支付系统相关设计开发经验者优先。</P>

      <A id="pos11"></A>
      <MasterTitle>高级Java开发工程师（Local Shop业务）</MasterTitle>
      <Title>岗位职责:</Title>
      <P>1、负责系统架构搭建、需求梳理、系统概要设计等；</P>
      <P>2、负责系统开发实现、代码优化、单元测试、集成测试等；</P>
      <P>3、负责系统的维护更新部署、处理系统线上问题；</P>
      <P>4、负责新技术预研与并应用到实际开发工作中等。</P>
      <Title>职位要求:</Title>
      <P>1、扎实的java基础，掌握java反射、NIO、多线程及锁等技术；</P>
      <P>2、熟悉spingboot、springmvc、mybatis等框架和协议的使用并了解部分原理；</P>
      <P>3、有基于springCloud（zk、eureka、feign、hystrix等）或Dubbo实际开发经验，并了解部分原理；</P>
      <P>4、熟悉常规的分布式架构，熟悉Redis、RabbitMQ、Kafka等开源中间件；</P>
      <P>5、熟练使用mysql，了解ES等数据库；</P>
      <P>6、有4年以上实际开发经验，有金融、电商等相关系统开发经验优先；</P>

      <A id="pos12"></A>

      <MasterTitle>Web前端工程师</MasterTitle>
      <Title>岗位职责:</Title>
      <P>1、根据产品设计需求，配合后台开发人员实现产品界面和功能，维护及优化前端页面性能；</P>
      <P>2、参与设计并编写WEB前端架构及应用，设计和完成通用业务组件库的搭建工作；</P>
      <P>3、负责与产品、UI和后端做良好的沟通，对产品能够提供持续的体验优化。</P>
      <Title>岗位要求:</Title>
      <P>1、本科及以上学历，3年以上前端工作经验；</P>
      <P>2、扎实的前端基础，优秀的编码习惯；</P>
      <P>3、对前端工程化有较好的理解，对网络、安全、性能优化有较好的理解；</P>
      <P>4、技术栈要求：对Vue、React、Webpack有深入的理解；</P>
      <P>5、有后端经验（Node、Java等）经验者优先。</P>

      <A id="pos13"></A>
      <A id="pos14"></A>
      <A id="pos15"></A>
      <MasterTitle>资深Java开发工程师</MasterTitle>
      <Title>工作职责:</Title>
      <P>1、负责支付核心系统相关模块代码的开发及优化；</P>
      <P>2、快速学习并深入理解业务知识，独立分析、设计合理的架构、系统开发与维护，高质量交付，保障资金安全；</P>
      <P>3、解决项目开发过程中遇到的技术难点问题，以技术驱动解决业务痛点问题，同时保证系统扩展性;</P>
      <P>4、负责系统的性能优化、数据一致性保障，迎接复杂业务挑战。</P>
      <Title>职位要求:</Title>
      <P>1、本科及以上学历，计算机、通信、电子、自动化相关领域专业；有国内外三方支付行业或金融行业相关工作经验；</P>
      <P>
        2、熟练掌握Java编程基础，理解IO、多线程等基础框架，对JVM的原理有一定的了解，具备良好的编码规范，熟悉面向对象和数据结构；
      </P>
      <P>3、熟悉SpringBoot、Dubbo、Mybatis等框架，熟悉Redis、RocketMQ等中间件，有研究过开源框架代码等优先；</P>
      <P>4、熟悉MySQL数据库，有良好的数据库设计能力和SQL编写及优化能力；</P>
      <P>5、具备优秀的代码阅读能力和编码能力；</P>
      <P>6、思维清晰缜密，工作细致耐心，有良好的沟通能力，善于分析和解决问题，有较强的团队协作精神和责任心。</P>

      <A id="pos16"></A>
      <A id="pos17"></A>
      <A id="pos18"></A>
      <MasterTitle>机器学习算法工程师</MasterTitle>
      <Title>岗位职责:</Title>
      <P>1、负责用户画像、风控模型、点击率模型等利用机器学习算法的技术探索实现；</P>
      <P>2、负责模型选型，算法建模，数据处理，以及模型训练与优化；</P>
      <Title>职位要求:</Title>
      <P>1、 具备2年以上机器学习/人工智能相关工作经验。计算机、数学、统计学及电子信息等相关专业本科及以上学历。</P>
      <P>2、熟悉一种以上常用的深度学习计算框架，包括但不局限 Tensorflow、Mxnet、PyTorch、Caffe 等；</P>
      <P>
        3、熟悉常用机器学习算法、深度学习算法及其原理，了解主流深度学习模型，另外至少对机器学习、图像检测与识别、语音识别中的一个领域有深入理解；
      </P>
      <P>4、掌握 C++/JAVA/Python 等常用编程语言。</P>

      <A id="pos19"></A>
      <MasterTitle>数据分析</MasterTitle>
      <Title>岗位职责:</Title>
      <P>
        1、负责相关业务的数据分析，包括但不限于产品分析、用户分析、运营分析等，能够构建项目分析相关的指标体系、分析框架，并根据分析结果提出可落地的业务策略建议；
      </P>
      <P>
        2、应用运用标准的方法论、（或数据挖掘和机器学习方法建立数据模型）解决实际问题，构建项目预期，并与业务部门沟通合作推动数据模型在实际业务中落地， 对营销活动效果完成分析闭环，并输出建议；
      </P>
      <P>
        3、与产品部门密切联系，提取、评估、挖掘产品的数据要求，梳理和转换出有效的、可持续建设的数据、指标模型需求，协同数仓完成。
      </P>
      <Title>职位要求:</Title>
      <P>1、全日制统招本科及以上学历，数学、统计学、计量经济学、数据挖掘等相关专业，3年以上数据分析经验；</P>
      <P>2、了解主流大数据产品、BI产品；熟练使用MySQL，SQL、Hive等语言； </P>
      <P>3、熟练使用MySQL等数据提取工具，熟练使用SPSS、R、SAS、Python等任一统计分析工具；</P>
      <P>4、熟悉数据生产加工流程，参与过比较完整的数据采集、整理、分析、落地（和建模）工作；</P>
      <P>5、数据分析、挖掘经验，有互联网大数据建模分析经验者优先； </P>
      <P>6、具备与技术人员沟通数据需求的能力，具备良好的分析和解决问题的能力，具备良好的项目推动能力和执行力； </P>
      <P>7、有较强的沟通能力、抗压能力、主动性及团队合作精神；</P>
      <P>8、有对业务理解拆解的方法论，能够快速解构业务，理解业务，并从数据角度提供优化思路；</P>
      <P>9、有某一行业相关从业经验和业务理解积累，深入理解行业生态，及其利益关系。</P>

      <A id="pos20"></A>
      <MasterTitle>数据平台Java开发工程师</MasterTitle>
      <Title>岗位职责:</Title>
      <P>1、负责数据平台实时计算业务功能的开发工作；</P>
      <P>2、负责数据平台风控引擎的架构与搭建；</P>
      <P>3、负责对数据平台高并发服务进行性能调优。</P>
      <Title>职位要求:</Title>
      <P>1、全日制本科或以上学历，计算机或相关专业；</P>
      <P>2、具有扎实的JAVA语言编程基础，2 年以上工作经验；</P>
      <P>3、有flink/spark等流批处理的开发和使用经验；</P>
      <P>4、熟悉zookeeper/kafka/Hadoop/spark等平台；</P>
      <P>5、良好的沟通和团队合作能力，具备很强的问题分析和解决能力。</P>

      <A id="pos21"></A>
      <MasterTitle>数仓开发工程师</MasterTitle>
      <Title>岗位职责:</Title>
      <P>1、参与opay数据中台数仓建设工作；</P>
      <P>2、负责数据仓库ETL流程的优化及解决相关技术问题；</P>
      <P>3、负责核心业务数据建模以及cube数据开发工作；</P>
      <P>4、优化和完善opay数据基础设施建设；</P>
      <P>5、负责数据内容产品落地，实现数据价值产品化；负责所在方向数据治理工作，保证数据稳定产出；</P>
      <Title>任职要求:</Title>
      <P>1、深入理解常用的数据建模理论，可独立把控数据仓库各层级的设计；</P>
      <P>2、熟悉Hadoop生态，精通Hdfs、Hive、Spark、MR开发，熟悉Presto、Flink，有任务调优经验；</P>
      <P>3、了解数据治理，从事过治理相关工作，理解数据治理的重要性；</P>
      <P>4、具备较强的编程能力和编程经验，至少熟悉Java/Python一门编程语言，熟悉linux系统，熟练使用Shell。</P>

      <A id="pos22"></A>
      <A id="pos23"></A>
      <A id="pos24"></A>
      <MasterTitle>高级JAVA研发工程师 </MasterTitle>
      <Title>职责描述:</Title>
      <P>1、负责独立风险管理模块（决策引擎、特征计算、函数集管理等）相关系统的开发工作；</P>
      <P>2、参与过系统整体技术选型、设计和技术研发；</P>
      <P>3、负责过系统的需求分析、概要设计、详细设计，编码和测试等多个环节；</P>
      <P>4、队技术方向有延展性，参与团队技术交流和分享活动，促进团队共同进步。</P>
      <Title>职位要求:</Title>
      <P>1、计算机相关专业本科以上学历，技术能力优异者可适当放宽；</P>
      <P>
        2、Java基础扎实，至少3年以上的开发经验，有多线程、高并发系统开发经验；熟悉Spring Boot、Spring
        MVC、FreeMarker等主流开源框架；{" "}
      </P>
      <P>
        3、对分布式框架开发、缓存处理、底层函数处理、数据流处理等环节有技术处理应用的实践经验，熟悉ES等分析技术的使用，掌握Redis内存数据库的使用，并熟悉内存数据计算优，了解常见消息队列ActiveMQ、Kafka等技术，熟悉Flink实时流数据处理技术；
      </P>
      <P>4、熟练掌握SQL开发能力，熟悉Mysql、Oracle等关系数据库，能独立完成分配的系统模块的设计、开发和维护工作；</P>
      <P>5、良好的编码习惯，具有非常强的责任心，能够承担压力，思路清晰、善于思考、能独立分析和解决问题；</P>
      <P>6、具备金融风险管理相关产品研发经验者优先；有英语沟通能力优先。</P>

      <A id="pos25"></A>
      <MasterTitle>高级风险模型工程师 </MasterTitle>
      <Title>岗位职责:</Title>
      <P>1、基于内外部大数据，负责开发风险管理中的评分模型、反欺诈模型等相关模型工作；</P>
      <P>2、负责推进风险管理数据仓库及集市建设，构建风险管理特征库；</P>
      <P>3、利用常用数理统计或机器学习算法，构建数据模型并建立模型效果评估标准；</P>
      <P>4、监控模型运行效果并及时优化，持续提升模型的精准度；</P>
      <P>5、建立模型运作的定期指标，优化模型的管理效率。</P>
      <Title>职位要求:</Title>
      <P>
        1、5年以上支付机构或者金融机构风险相关的工作经验，经济、金融、数学、统计、计算机等相关专业硕士以上学历（
        本科工作经验7年以上可放宽 ），博士优先；
      </P>
      <P>2、数据驱动思维，精通SQL，至少精通Python、R、SAS中的一种；</P>
      <P>
        3、熟练应用梳理统计或机器学习算法，熟悉DT、LR、GBDT、XGboost、LGM等，能够理解算法原理，并有能力研究和优化算法；
      </P>
      <P>4、对机器学习的自学习模式有一定了解，熟悉模型开发后的系统对接与部署实现；</P>
      <P>5、具备良好的沟通能力与学习能力、工作积极主动具有创新精神，英语口语及读写能力佳者优先。</P>

      <A id="pos26"></A>
      <A id="pos27"></A>
      <A id="pos28"></A>
      <MasterTitle>1. 安全中心</MasterTitle>
      <Title>工作职责:</Title>
      <P>1、负责公司所有线上业务和内部系统的web渗透、安全扫描、系统修复建议等工作；</P>
      <P>2、负责公司各应用系统的安全评估与整改推进;</P>
      <P>3、负责跟踪新的安全漏洞、业界最新的攻防技术和解决方案、安全培训等;</P>
      <Title>任职要求:</Title>
      <P>
        1、熟悉常见安全漏洞原理，掌握或精通Web安全渗透、APP程序漏洞分析、入侵和攻击检测、Fuzzing中的两项及以上技能。及漏洞成因、渗透思路和方法；
      </P>
      <P>2、能熟练使用burpsuite、AWVS、appscan等黑盒测试工具;</P>
      <P>3、熟悉Java语言以及其主流WEB框架（spring/struts2），对Java web应用的架构比较熟悉;</P>
      <P>4、3年以上Web安全测试经验，过去的工作中独立发现过很多中高危Web安全漏洞或APP安全漏洞</P>
      <P>5、有过移动APP安全渗透测试经验。</P>
      <P>6、良好的学习沟通能力和团队合作精神，有很强的责任心</P>
      <Title>可选加分项:</Title>
      <P>1、有互联网公司安全从业经历的；</P>
      <P>2、有SRC和漏洞应急响应经验的</P>
      <P>3、有过CVE贡献的</P>

      <A id="pos29"></A>
      <MasterTitle>SDL专家</MasterTitle>
      <Title>岗位职责:</Title>
      <P>1、全面参与公司项目和产品的安全需求评估，包括方案评审、威胁建模、安全测试、安全整改等。</P>
      <P>2、完善优化SDL体系流程，践行安全左移思想，构建内部安全测试能力库和工具库。</P>
      <P>3、负责安全漏洞研究与安全事件应急响应，输出高效的解决方案。</P>
      <P>4、负责内部赋能同时推动团队和公司整体安全能力的提升。</P>
      <Title>任职要求:</Title>
      <P>1、3年以上Web安全测试经验，熟悉SDL/DevSecOps安全开发流程；</P>
      <P>2、熟悉owasp top10安全漏洞的原理、成因以及正确的解决方案；</P>
      <P>3、能熟练使用burpsuite、AWVS、appscan、Fortify等黑白盒测试工具；</P>
      <P>4、熟悉Java语言以及其主流WEB框架（spring/struts2），对Java web应用的架构比较熟悉；</P>
      <P>5、良好的学习沟通能力和团队合作精神，有很强的责任心</P>
      <P>6、3年以上WEB安全工作经验。 </P>

      <A id="pos30"></A>
      <A id="pos31"></A>
      <A id="pos32"></A>
      <MasterTitle>高级产品经理</MasterTitle>
      <Title>岗位职责:</Title>
      <P>
        1、负责POS收单规划及产品发展方向的整体把握和工作部署，结合公司发展战略，进行产品的发展规划，明晰定位、目标、策略并落实实施；
      </P>
      <P>2、把握产品发展趋势，分析跟踪竞争对手，挖掘用户需求，定期完成分析报告或产品竞争战略和计划；</P>
      <P>3、负责制定产品规范，对产品进行全面设计，并与技术人员一起，综合利用各种技术手段，完成产品研发和优化；</P>
      <P>4、 组织产品与运营模式的内外部调研分析，进行用户数据分析并找到对应的提升方案，为决策提供核心依据。</P>
      <Title>任职要求:</Title>
      <P>
        1、
        3年或以上经验。对产品有自己的见解，有通过自己的努力做出一款能为用户提供舒服的使用体验，甚至改变用户的产品的欲望；
      </P>
      <P>2、有相关第三方支付、线下收单、SAAS收银、生活缴费经验者优先；有敏锐的市场洞察力，知识范围广阔； </P>
      <P>3、有参与产品前期分析及市场定位的工作经验，能够独立完成产品功能及数据效果分析并提出相应的产品优化建议； </P>
      <P>4、具有owner意识，能够主动推动工作向前并形成闭环； </P>
      <P>5、流利的英语听说读写是加分项。</P>

      <A id="pos33"></A>
      <A id="pos34"></A>
      <A id="pos35"></A>
      <MasterTitle>支付通道开发专家</MasterTitle>
      <Title>岗位职责:</Title>
      <P>
        1、负责核心技术问题的攻关解决，系统优化，代码质量管理，参与实现方案的设计和前期开发，对通用技术整理提高技术复用；
      </P>
      <P>2、负责与银行、第三方等机构进行产品分析、设计、对接，确保项目质量和进度；</P>
      <P>3、负责技术疑难问题定位解决，定制化开发、项目实施技术问题支持；</P>
      <P>4、参与制定开发规范、技术标准，编写相应的技术、测试、设计、需求等规范文档；</P>
      <Title>任职要求:</Title>
      <P>1、具有银行通道产品对接开发经验，了解国内外支付产品流程；</P>
      <P>2、有带领团队经验，且能够很好的协调带领团队及时快速对接通道；</P>
      <P>3、扎实的Java编程功底和良好的编码规范，熟练掌握J2EE相关开发技术；</P>
      <P>4、熟练掌握Java MVC开发架构模式，熟悉数据库，具备深厚的SQL功底，并有着丰富的数据库设计经验优先考虑；</P>
      <P>5、具有学习能力、承受压力能力，独立分析及解决问题的能力；</P>
      <P>6、具备一定文档编写能力，熟悉管理、计划、设计等相关文档的编写；</P>
      <P>7、具有很强的沟通及协调能力，勇于技术革新和创新，具有团队协作和敬业精神；具有较强的工作责任心和事业心。</P>
      <P>8、熟悉分布式技术、通信协议，如WebService、Restful、SpringCloud全家桶等技术者优先；</P>
      <P>9、思维清晰敏捷，逻辑分析能力强，高度责任感和较强适应力，具有良好的团队合作精神；</P>
      <P>10、在大数据、高并发等方面有实际工作经验者优先。</P>
    </Container>
  );
};

Info.defaultProps = {
  position: "",
};

Info.propTypes = {
  position: PropTypes.string,
};

export default Info;
