import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Fade from "react-reveal/Fade";
import { Container } from "../../../global";
import HeroSection from "../../hero";
import {
  StyledBackgroundSection,
  HeaderWrapper,
  HeaderTextGroup,
  Flex,
  ImageWrapper,
  StyledImageWrapper,
  StyledMainImage,
} from "./style";

const Header = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      headerImage: file(sourceInstanceName: { eq: "product" }, name: { eq: "society-header" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <StyledBackgroundSection>
      <HeaderWrapper id="top">
        <Container>
          <Flex>
            <HeaderTextGroup>
              <Fade bottom delay={250}>
                <HeroSection.Title>Experienced Hires</HeroSection.Title>
                <HeroSection.Title>Base Beijing</HeroSection.Title>
              </Fade>
            </HeaderTextGroup>
            <ImageWrapper>
              <StyledImageWrapper>
                <StyledMainImage objectFit="contain" fluid={data.headerImage.childImageSharp.fluid} width="100%" />
              </StyledImageWrapper>
            </ImageWrapper>
          </Flex>
        </Container>
        {children}
      </HeaderWrapper>
    </StyledBackgroundSection>
  );
};

export default Header;
