import React, { useState } from "react";
import PropTypes from "prop-types";
import "antd/dist/antd.css";
import "./index.css";
import { Tree, Input } from "antd";

import { TreeItem, Tip } from "../../common/trees/style";

const Trees = props => {
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [sectedKeys, setSectedKeys] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [showTip, setShowTip] = useState(false);

  const { Search } = Input;

  const gData = [
    {
      key: "0",
      title: "APP",
      children: [
        {
          key: "1",
          title: "Product",
          children: [
            {
              key: "2",
              title: "APP Product Manager",
            },
            {
              key: "3",
              title: "Payment Product Manager",
            },
            {
              key: "4",
              title: "Product operation",
            },
            {
              key: "5",
              title: "Product Manager of Customer Service System",
            },
            {
              key: "6",
              title: "Senior Product Manager of Local Shop",
            },
          ],
        },
        {
          key: "7",
          title: "Technological Development",
          children: [
            {
              key: "8",
              title: "Risk Control Model Engineer",
            },
            {
              key: "9",
              title: "Payment Account Development Expert",
            },
            {
              key: "10",
              title: "Senior Payment Developer",
            },
            {
              key: "11",
              title: "Senior Java Developer",
            },
            {
              key: "12",
              title: "Web Front-end Engineer",
            },
          ],
        },
      ],
    },
    {
      key: "13",
      title: "Online Payment Gateway",
      children: [
        {
          key: "14",
          title: "Technological Development",
          children: [
            {
              key: "15",
              title: "Senior Java Developer",
            },
          ],
        },
      ],
    },
    {
      key: "16",
      title: "Data Middle Platform",
      children: [
        {
          key: "17",
          title: "Technological Development",
          children: [
            {
              key: "18",
              title: "Machine Learning Algorithm Engineer",
            },
            {
              key: "19",
              title: "Data analyst",
            },
            {
              key: "20",
              title: "Java Developer for Data Platform",
            },
            {
              key: "21",
              title: "Data Warehouse Developer",
            },
          ],
        },
      ],
    },
    {
      key: "22",
      title: "Risk Control Middle Platform",
      children: [
        {
          key: "23",
          title: "Technological Development",
          children: [
            {
              key: "24",
              title: "Senior Java Developer",
            },
            {
              key: "25",
              title: "Senior Risk Model Engineer",
            },
          ],
        },
      ],
    },
    {
      key: "26",
      title: "Secuirity Center",
      children: [
        {
          key: "27",
          title: "Technological Development",
          children: [
            {
              key: "28",
              title: "WEB Penetration Testing Specialist/Senior Safety Engineer",
            },
            {
              key: "29",
              title: "SDL specialist",
            },
          ],
        },
      ],
    },
    {
      key: "30",
      title: "Offline Business",
      children: [
        {
          key: "31",
          title: "Product",
          children: [
            {
              key: "32",
              title: "Product Manager",
            },
          ],
        },
        {
          key: "33",
          title: "Technological Development",
          children: [
            {
              key: "35",
              title: "Payment channel development expert",
            },
          ],
        },
      ],
    },
  ];

  const dataList = [
    {
      key: "0",
      title: "Technological",
    },
  ];
  const generateList = data => {
    for (let i = 0; i < data.length; i++) {
      const node = data[i];
      const { key, title } = node;
      dataList.push({ key, title: title });
      if (node.children) {
        generateList(node.children);
      }
    }
  };
  generateList(gData);

  const getParentKey = key => {
    // let parentKey;
    // for (let i = 0; i < tree.length; i++) {
    //   const node = tree[i];
    //   if (node.children) {
    //     if (node.children.some(item => item.key === key)) {
    //       parentKey = node.key;
    //     } else if (getParentKey(key, node.children)) {
    //       parentKey = getParentKey(key, node.children);
    //     }
    //   }
    // }

    return key;
  };

  const onExpand = expandedKeys => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };

  const onChange = e => {
    const { value } = e.target;
    const expandedKeys = dataList
      .map(item => {
        if (item.title.toLocaleLowerCase().indexOf(value) > -1) {
          return getParentKey(item.key, gData);
        }
        return null;
      })
      .filter((item, i, self) => item && self.indexOf(item) === i);
    if (expandedKeys.length == 0) {
      setShowTip(true);
      setTimeout(() => {
        setShowTip(false);
      }, 2000);
      return;
    }
    setExpandedKeys(expandedKeys);
    setSectedKeys(expandedKeys);
    props.getSelectNode([expandedKeys[0]]);
    setSearchValue(value);
    setAutoExpandParent(true);
  };

  // if (props.searchKey && searchValue != props.searchKey) {
  //   onChange(props.searchKey);
  // }

  const selectNode = value => {
    setSectedKeys(value);
    props.getSelectNode(value);
  };

  const loop = data =>
    data.map(item => {
      const index = item.title.indexOf(searchValue);
      const beforeStr = item.title.substr(0, index);
      const afterStr = item.title.substr(index + searchValue.length);
      const title =
        index > -1 ? (
          <span>
            {beforeStr}
            <span className="site-tree-search-value">{searchValue}</span>
            {afterStr}
          </span>
        ) : (
          <span>{item.title}</span>
        );
      if (item.children) {
        return { title, key: item.key, children: loop(item.children) };
      }

      return {
        title,
        key: item.key,
      };
    });
  return (
    <TreeItem>
      <Search placeholder="Search" onChange={onChange} />
      <Tree
        onExpand={onExpand}
        expandedKeys={expandedKeys}
        selectedKeys={sectedKeys}
        autoExpandParent={autoExpandParent}
        treeData={loop(gData)}
        onSelect={selectNode}
      />
      {showTip ? <Tip>Sorry, you can search for other suitable jobs</Tip> : null}
    </TreeItem>
  );
};

Trees.defaultProps = {
  getSelectNode: () => {},
  searchKey: "",
};

Trees.propTypes = {
  getSelectNode: PropTypes.func,
  searchKey: PropTypes.string,
};

export default Trees;
