import React, { useState } from "react";
import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
import Navigation from "../components/common/navigation/navigation";
import Header from "../components/sections/society/header";
import HeaderCh from "../components/sections/societyCh/header";
import Search from "../components/sections/search";

import styled from "styled-components";

import Content from "../components/sections/society/content";
import ContentCH from "../components/sections/societyCh/content";

const MiniPOS = () => {
  const [value, setValue] = useState("");
  const [type, setType] = useState("en");

  const changeValue = values => {
    setValue(values);
    // 两次设置相同值不触发render，临时解决
    setValue(value + " ");
  };

  const selectLanguage = (e, type) => {
    e.stopPropagation();
    setType(type);
  };

  const jump = url => {
    location.href = url;
  };

  return (
    <Layout style={{ overflow: "hidden" }}>
      <SEO title="School" />
      <Navigation background="white" showBanner={false} />
      {type === "en" ? (
        <>
          <Header>
            <Search changeValue={changeValue} />
            <BtnGroup>
              <Btn className={type === "en" ? "active" : null} onClick={e => selectLanguage(e, "en")}>
                EN
              </Btn>
              <Btn className={type === "cn" ? "active" : null} onClick={e => selectLanguage(e, "cn")}>
                中文
              </Btn>
            </BtnGroup>
          </Header>
          <SendBtn>
            <Button onClick={() => jump("https://app.mokahr.com/apply/opay/39992")}>Send my resume</Button>
          </SendBtn>
          <Content value={value}></Content>
        </>
      ) : (
        <>
          <HeaderCh>
            <Search changeValue={changeValue} />
            <BtnGroup>
              <Btn className={type === "en" ? "active" : null} onClick={e => selectLanguage(e, "en")}>
                EN
              </Btn>
              <Btn className={type === "cn" ? "active" : null} onClick={e => selectLanguage(e, "cn")}>
                中文
              </Btn>
            </BtnGroup>
          </HeaderCh>
          <SendBtn>
            <Button onClick={() => jump("https://app.mokahr.com/apply/opay/39992")}>我要申请</Button>
          </SendBtn>
          <ContentCH value={value}></ContentCH>
        </>
      )}
    </Layout>
  );
};

export default MiniPOS;

const SendBtn = styled.div`
  text-align: center;
  padding: 16px 0 0;
  position: fixed;
  width: 100%;
  bottom: 10px;

  ${({ theme }) => theme.mq.md`
    text-align: center;
    padding: 46px 0 40px;
    position: static;
  `}
`;

const Button = styled.div`
  width: 246px;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  font-weight: 900;
  background: #1dcb9b;
  box-shadow: 0px 12px 26px 0px rgba(0, 0, 0, 0.05);
  border-radius: 45px;
  font-family: Graphik-Black;
  display: inline-block;

  ${({ theme }) => theme.mq.md`
    width: 450px;
    height: 90px;
    background: #1dcb9b;
    box-shadow: 0px 12px 26px 0px rgba(0, 0, 0, 0.05);
    border-radius: 45px;
    font-family: Graphik-Black;
    font-size: 36px;
    color: #ffffff;
    letter-spacing: 0;
    text-align: center;
    font-weight: 900;
    line-height: 90px;
    display: inline-block;
    cursor: pointer;
  `}
`;

const BtnGroup = styled.div`
  position: absolute;
  top: 90px;
  right: 30px;
  z-index: 999;

  ${({ theme }) => theme.mq.md`
    top: 110px;
    right: 125px;
  `}
`;
const Btn = styled.div`
  width: 56px;
  height: 20px;
  border: 1px solid #1dcb9b;
  border-radius: 10px;
  line-height: 20px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  &:first-child {
    margin-right: 6px;
  }
  &.active {
    background: #1dcb9b;
  }
`;
