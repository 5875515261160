import styled from "styled-components";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import Img from "gatsby-image";

const StyledBackgroundSection = styled.div`
  background: linear-gradient(110.57deg, rgba(228, 255, 248, 0.1) -2.17%, #e4fff8 99.02%);
  position: relative;
  width: 100%;
  z-index: 4;
`;

const HeaderWrapper = styled.div`
  position: relative;
`;

const HeaderTextGroup = styled.div`
  align-items: center;
  color: ${props => props.theme.colors.header};
  display: flex;
  flex-direction: column;
  z-index: 2;

  ${({ theme }) => theme.mq.lg`
  padding: 0 50px 0 0;
    align-items: flex-start;
    flex-basis: 60%;
  `}

  ${({ theme }) => theme.mq.xl`
  flex-basis: 51%;
    align-items: flex-start;
  `}
`;

const DownloadButtonWrapper = styled.div`
  display: flex;
  margin: 0 auto;

  ${({ theme }) => theme.mq.lg`
  justify-self: flex-start;
    margin: 0;
  `}
`;

const Flex = styled.div`
  align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 180px;

  ${({ theme }) => theme.mq.lg`
  flex-direction: row;
    padding-top: 210px;
  `}
`;

const ImageWrapper = styled.div`
  margin-top: 5rem;
  position: relative;

  ${({ theme }) => theme.mq.lg`
  display: flex;
    flex-basis: 55%;
    flex: 1;
    margin-top: 0;
  `}
`;

const StyledImageWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 310px;
  position: relative;
  width: 100%;

  ${({ theme }) => theme.mq.md`
  max-width: 355px;
  `}

  ${({ theme }) => theme.mq.lg`
  max-width: 100%;
  `}

  ${({ theme }) => theme.mq.xl`
  margin-top: -60px;  
  `}
`;

const StyledMainImage = styled(Img)`
  width: 100%;
`;

const CTA = styled(OutboundLink)`
  svg {
    width: 128px;
    height: 42px;
  }

  ${({ theme }) => theme.mq.md`
  svg {
    width: 179px;
    height: 60px;
  }
  `}

  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }

  &:not(:last-child) {
    margin-right: 16px;
  }
`;

export {
  StyledBackgroundSection,
  HeaderWrapper,
  HeaderTextGroup,
  DownloadButtonWrapper,
  Flex,
  CTA,
  StyledMainImage,
  ImageWrapper,
  StyledImageWrapper,
};
