import React from "react";
import PropTypes from "prop-types";
// import { graphql, useStaticQuery } from "gatsby";
import { Container, MasterTitle, Title, P, A } from "../../common/info/style";

const Info = props => {
  const scrollToAnchor = anchorName => {
    if (anchorName) {
      if (typeof window !== `undefined`) {
        let anchorElement = document.getElementById(anchorName);
        if (anchorElement) {
          anchorElement.scrollIntoView(100);
        }
      }
    }
  };
  if (props.position) {
    scrollToAnchor("pos" + props.position);
  }

  return (
    <Container>
      {/** product*/}
      <A id="pos1"></A>
      <A id="pos2"></A>
      <MasterTitle>APP Product Manager</MasterTitle>
      <Title>Job Description:</Title>
      <P>
        1、Participate in the product planning of the African payment APP, and understand and implement the company’s
        goals and strategies;
      </P>
      <P>
        2、Grasp the development trend of overseas Fintech companies, follow up on the business data of payment products
        in Europe, America, and Africa to analyze potential competitors, and explore the payment needs and habits of
        African users;
      </P>
      <P>3、Work with technicians to implement and optimize various product strategies;</P>
      <P>4、Develop plans for product optimization through analyzing user data and behavioral data;</P>
      <P>
        5、Responsible for user experience and user growth, and actively drive forward the business to meet the target.
      </P>
      <Title>Job Requirements:</Title>
      <P>1、Over 5 years of experience in product planning;</P>
      <P>2、Candidate with successful Internet product experience and overseas product experience is preferred;</P>
      <P>
        3、Experience in prior-period product analysis and market positioning is preferred; competence in independently
        analyzing product functions and data effect and putting forward optimization suggestions;
      </P>
      <P>4、Have a strong sense of responsibility, teamwork and innovative spirit, and a strategic vision;</P>
      <P>5、Clear logic, open mindset, and strong summary skill. </P>

      <A id="pos3"></A>
      <MasterTitle>Payment Product Manager</MasterTitle>
      <Title>Job Description:</Title>
      <P>
        1、Improve interactive functions of the bill payment products in the APP, optimize the OPay Wallet APP in terms
        of overseas bill payment scenarios and payment-related products independently, and improve user experience;
      </P>
      <P>
        2、Continuously analyze data of payment products and receive real feedback from users to formulate iteration
        plans and improve user experience;
      </P>
      <P>
        3、Seek to increase payment orders, and enhance differentiated product value by exploring different payment
        scenarios and different user scenarios in the local area.
      </P>
      <Title>Job Requirements:</Title>
      <P>1、Bachelor’s degree or above, with more than 3 years of working experience in payment products;</P>
      <P>2、Extremely sensitive to customer experience, and be detail-oriented;</P>
      <P>
        3、Lead or participate in mobile product design, and familiar with Internet products and services; experience in
        payment products is preferred;
      </P>
      <P>
        4、Strong communication skills, coordination skills, and advancing skills; result-oriented mentality; excellent
        data analysis skills.
      </P>

      <A id="pos4"></A>
      <MasterTitle>Product operation</MasterTitle>
      <Title>Job Description:</Title>
      <P>
        1、Responsible for improving bill payment efficiency and the daily operation and maintenance of payment
        products, including communicating and following up on solving errors occurred on business transactions,
        accounting, and channels;
      </P>
      <P>
        2、Responsible for prior period communication of payment transaction scenarios, confirm the business context and
        its commercial value, and formulate collection and payment solutions that meet the requirements;
      </P>
      <P>
        3、Responsible for business value delivery, business monitoring, data analysis, and collecting and sorting
        customers’ feedback after the product is launched;
      </P>
      <P>
        4、Responsible for competitive product analysis by collecting and analyzing related products in the industry to
        provide support for product design.
      </P>
      <Title>Job Requirements:</Title>
      <P>1、Bachelor’s degree or above, with more than 2 years of product operation experience;</P>
      <P>
        2、Teamwork spirit, good communication skills, fast learning ability, responsibility, and anti-pressure ability;
      </P>
      <P>3、Experience in payment platforms or product operation is preferred;</P>
      <P>4、Candidate who can use English as a working language is preferred.</P>

      <A id="pos5"></A>
      <MasterTitle>Product Manager of Customer Service System</MasterTitle>
      <Title>Job Description:</Title>
      <P>
        1、Responsible for planning, demand discovery, function design, and promotion for the customer service system;
      </P>
      <P>
        2、Deeply understand customer service and business needs, aim at improving customer service experience and
        efficiency, seek improvement in processes and products and enhance customer service with products and tools;
      </P>
      <P>
        3、Make sure the product launched is of high quality, and conduct effect evaluation and product iteration
        afterward.
      </P>
      <Title>Job Requirements:</Title>
      <P>1、More than 3 years of experience in customer service system or CRM system;</P>
      <P>
        2、Good communication skills, logical thinking, and data analysis skills; and be able to independently plan and
        design products based on complex business scenarios;
      </P>
      <P>3、Team management experience.</P>

      <A id="pos6"></A>
      <MasterTitle>Senior Product Manager of Local Shop</MasterTitle>
      <Title>Job Description:</Title>
      <P>
        1、Responsible for the product design of OPay Local Shopping (Benchmark against Meituan and Dianping), and
        provide good user experience;
      </P>
      <P>
        2、Participate in designing marketing approaches for merchants on the platform, and create matching products
        that cater to users’ rights and interests to increase the number of users and orders;
      </P>
      <P>
        3、Work closely with upstream, downstream, and horizontal product teams to establish long-term product
        capabilities and operating mechanisms for user conversion and retention;
      </P>
      <P>
        4、Investigate overseas users and competitors regularly, grasp the development trends of the industry and
        competitors, and update product strategies according to market change.
      </P>
      <Title>Job Requirements:</Title>
      <P>
        1、Over 3 years of experience in Internet products, and more than 1 year of experience in e-commerce/O2O
        products on client side; experience in food delivery and overseas products is preferred;
      </P>
      <P>
        2、Ability to break the project down based on business goals, strong user demand insights, good aesthetics
        judgment, and persistence in improving user experience;
      </P>
      <P>
        3、Have strong logical thinking and data analysis skills, think independently into the essence of problems, and
        review and accumulate experience;
      </P>
      <P>
        4、Independently lead large projects or cross-functional projects, with good execution, communication, and
        collaboration skills;
      </P>
      <P>5、Fluency in English is preferred.</P>

      <A id="pos7"></A>
      <A id="pos8"></A>
      <MasterTitle>Risk Control Model Engineer</MasterTitle>
      <Title>Job Description:</Title>
      <P>
        1、Responsible for the research and development, iteration, and optimization of the anti-fraud risk control
        model of the OPay credit payment project, including model requirement analysis, model design, model development,
        model review, effect monitoring, etc.;
      </P>
      <P>
        2、Establish pre-, during, and post-event risk models for products based on the characteristics of users and
        transactions, and monitor, evaluate, and optimize the implemented risk models;
      </P>
      <P>
        3、Based on business requirements, build machine learning models for different scenarios, complete model fusion,
        and deploy machine learning models;
      </P>
      <P>
        4、Explore characteristics of individuals and networking groups from unstructured data, and continuously
        optimize the features of graphs to support anti-fraud models and strategies.
      </P>
      <Title>Job Requirements:</Title>
      <P>1、Bachelor’s degree or above; major in mathematics, statistics, computer, etc.;</P>
      <P>
        2、More than two years of data mining or modeling experience; background in e-commerce, payment, and internet
        finance is preferred;
      </P>
      <P>3、Familiar with machine learning algorithms, such as GBDT, XGBOOST, random forest, and RNN;</P>
      <P>4、Proficient in feature engineering techniques in the fields of payment and credit risk control;</P>
      <P>5、Proficient in SQL, Python, R, SAS, and other tools.</P>

      <A id="pos9"></A>
      <MasterTitle>Payment Account Development Expert</MasterTitle>
      <Title>Job Description:</Title>
      <P>
        1、Build the account system of the OPay payment platform; research, develop and maintain its business system and
        technical products;
      </P>
      <P>
        2、Participate in building a universal, flexible, and intelligent account platform to meet the needs of complex
        business in multiple scenarios;
      </P>
      <P>
        3、Responsible for system analysis and design of related products, and conduct detailed design and coding
        independently to make sure projects are delivered in time and with high quality.
      </P>
      <Title>Job Requirements:</Title>
      <P>
        1、Over 5 years of experience in software development, skilled at Git version control system and Linux commands;
      </P>
      <P>2、Solid foundation in JAVA language with good coding habits; DDD related experience is preferred;</P>
      <P>
        3、Familiar with relational databases such as Mysql and non-relational databases such as Elasticsearch and
        Redis;
      </P>
      <P>4、Familiar with the microservice technology stack, including SpringBoot, SpringCloud, RPC framework, etc.;</P>
      <P>5、Familiar with message-oriented middleware such as RocketMq and Kafka;</P>
      <P>6、Solid back-end foundation, including data structure, multithreading, memory, JDK;</P>
      <P>
        7、Familiar with the design and development of system structures, accounting, reconciliation, capital flow, and
        financial payment of banks’ clearing and settlement systems.
      </P>

      <A id="pos10"></A>
      <MasterTitle>Senior Payment Developer</MasterTitle>
      <Title>Job Description:</Title>
      <P>
        1、Responsible for modules development, including functions design, product requirements analysis, and
        technological implementation;
      </P>
      <P>2、Design, code, test, and write documents of key/important modules;</P>
      <P>3、Participate in code design, review, and approval;</P>
      <P>4、Help the team solve technological challenges;</P>
      <P>5、Participate in debugging system stability, performance, and scalability.</P>
      <Title>Job Requirements:</Title>
      <P>1、Bachelor’s degree or above, with over 5 years of experience in JAVA software development;</P>
      <P>
        2、Solid background in JAVA programming, familiar with algorithms, data structures, multithreading, network
        programming, etc.;
      </P>
      <P>
        3、Familiar with mainstream frameworks (Spring, Hibernate, Mybatis, WebMVC), and proficient in using jQuery,
        ExtJS, CSS, etc.
      </P>
      <P>4、Familiar with Sybase, SQLServer, MongoDb; familiar with Linux, Tomcat, Memcache, SVN, etc.;</P>
      <P>5、Familiar with Remote Procedure Call, such as RMI, Hessian, WebServices, etc.;</P>
      <P>
        6、Practical experience in database sharding and optimization; familiar with configuring and tuning Tomcat and
        Nginx in Linux;
      </P>
      <P>
        7、Experience in developing large-scale concurrent systems and in designing and developing financial and
        electronic payment systems is preferred.
      </P>

      <A id="po11"></A>
      <MasterTitle>Senior Java Developer</MasterTitle>
      <Title>Job Description:</Title>
      <P>1、Responsible for system architecture, requirements sorting, system outline design, etc.;</P>
      <P>
        2、Responsible for system development and implementation, code optimization, unit testing, integration testing,
        etc.;
      </P>
      <P>3、Responsible for system maintenance, update and deployment, and handle online problems of the system;</P>
      <P>4、Responsible for the pre-research and application of new technologies.</P>
      <Title>Job Requirements:</Title>
      <P>
        1、Solid background in Java, mastering java reflection, NIO, multithreading, lock, and related technologies;
      </P>
      <P>
        2、Familiar with spingboot, springmvc, mybatis, and other frameworks and protocols and the principles behind
        them;
      </P>
      <P>
        3、Development experience based on springCloud (zk, eureka, feign, hystrix, etc.) or Dubbo, and understand their
        principles;
      </P>
      <P>4、Familiar with conventional distributed architecture and such as Redis, RabbitMQ, and Kafka;</P>
      <P>5、Skilled at Mysql, and know about databases such as ES;</P>
      <P>
        6、Over 4 years of actual development experience, and experience in developing financial, e-commerce, and other
        related systems is preferred;
      </P>

      <A id="pos12"></A>

      <MasterTitle>Web Front-end Engineer</MasterTitle>
      <Title>Job Description:</Title>
      <P>
        1、Based on product requirements, cooperate with back-end developers to create product interfaces and functions,
        and maintain and optimize front-end web performance;
      </P>
      <P>
        2、Design and create WEB front-end architecture and applications, and design and set up a general business
        components library;
      </P>
      <P>3、Work with teams of product, UI, and backend to continuously improve the product experience.</P>
      <Title>Job Requirements:</Title>
      <P>1、Bachelor’s degree or above, with more than 3 years of front-end work experience;</P>
      <P>2、Solid background in the front end, and excellent coding habits;</P>
      <P>3、Good understanding of front-end engineering, network, security, and performance optimization;</P>
      <P>4、Deep understanding of technology stacks, including Vue, React, and Webpack;</P>
      <P>5、Back-end experience (Node, Java, etc.) is preferred.</P>

      <A id="pos13"></A>
      <A id="pos14"></A>
      <A id="pos15"></A>
      <MasterTitle>Senior Java Developer</MasterTitle>
      <Title>Job Description:</Title>
      <P>1、Develop and optimize relevant module codes of the core payment system;</P>
      <P>
        2、Master our business fastly, analyze and design feasible architecture independently, take on system
        development and maintenance, and ensure high-quality delivery to guarantee the security of funds;
      </P>
      <P>
        3、Solve technological difficulties in the process of project development, and solve business pain points in a
        technology-driven manner, and ensure system scalability as well;
      </P>
      <P>
        4、Responsible for system performance optimization and data consistency, and dare to take on complex business
        challenges.
      </P>
      <Title>Job Requirements:</Title>
      <P>
        1、Bachelor’s degree or above, major in computer, communication, electronics, automation, and related fields;
        relevant work experience in industries of third-party payment or finance at home and abroad;
      </P>
      <P>
        2、Skilled at Java, understand basic frameworks such as IO and multithreading, understand principles of JVM,
        have good coding habits, and familiar with object-oriented programming and data structures;
      </P>
      <P>
        3、Familiar with SpringBoot, Dubbo, Mybatis and other frameworks, familiar with Redis, RocketMQ, and other
        middlewares; knowledge in open source framework code is preferred;
      </P>
      <P>4、Familiar with MySQL database, and skilled at database design and SQL writing and optimization;</P>
      <P>5、Excellent code reading and coding ability;</P>
      <P>
        6、Demonstrate logical thinking and good communication skills, be detail-oriented and patient at work, good at
        analyzing and solving problems, and have a strong teamwork spirit and a sense of responsibility.
      </P>

      <A id="pos16"></A>
      <A id="pos17"></A>
      <A id="pos18"></A>
      <MasterTitle>Machine Learning Algorithm Engineer </MasterTitle>
      <Title>Job Description:</Title>
      <P>
        1、Use machine learning algorithms to create user portraits, risk control models, click-through rate models,
        etc.;
      </P>
      <P>
        2、Responsible for model selection, algorithm modeling, data processing, and model training and optimization;
      </P>
      <Title>Job Requirements:</Title>
      <P>
        1、Over 2 years of work experience in machine learning/artificial intelligence. Bachelor’s degree or above in
        computer, mathematics, statistics, electronic information, and other related majors;
      </P>
      <P>
        2、Familiar with at least one deep learning computing framework, including but not limited to Tensorflow, Mxnet,
        PyTorch, and Caffe;
      </P>
      <P>
        3、Familiar with commonly used machine learning algorithms, deep learning algorithms, and their principles,
        understand mainstream deep learning models, and have an in-depth understanding of at least one area in ​​machine
        learning, image detection and recognition, and speech recognition;
      </P>
      <P>4、Master popular programming languages,​such as C++, JAVA, and Python.</P>

      <A id="pos19"></A>
      <MasterTitle>Data analyst</MasterTitle>
      <Title>Job Description:</Title>
      <P>
        1、Responsible for data analysis, including but not limited to product analysis, user analysis, and operation
        analysis; build indicator systems and analysis frameworks for related projects, and put forward feasible
        business strategy proposals based on the analysis results;
      </P>
      <P>
        2、Apply standard methodology (or use data mining and machine learning methods to build data models) to solve
        problems, establish expectations, and work with different departments to implement data models, complete
        closed-loop analysis of marketing effects, and offer suggestions;
      </P>
      <P>
        3、Closely work with the product department to extract, evaluate, and discover data requirements of the product,
        and use the data warehouse to sort out and generate effective and sustainable data and index model requirements.
      </P>
      <Title>Job Requirements:</Title>
      <P>
        1、Full-time Bachelor’s degree or above in mathematics, statistics, econometrics, data mining, and other related
        majors, with more than 3 years of experience in data analysis;
      </P>
      <P>
        2、Understand mainstream big data products and BI products; proficient in MySQL, SQL, Hive, and other languages;
      </P>
      <P>
        3、Proficient in using data extraction tools such as MySQL, and any statistical analysis tools such as SPSS, R,
        SAS, Python, etc.;
      </P>
      <P>
        4、Familiar with the data generation and processing, participated in data collection, sorting, analysis, landing
        (and modeling);
      </P>
      <P>5、Data analysis and mining; experience in Internet big data modeling and analysis is preferred;</P>
      <P>
        6、Ability to communicate data requirements with technological personnel, good analysis and problem-solving
        skills, and ability to advance and execute projects;
      </P>
      <P>7、Strong communication skills, anti-stress ability, proactiveness, and teamwork spirit;</P>
      <P>
        8、Have unique insights in and methods to understand and break down projects and offer optimization proposals
        from a data perspective;
      </P>
      <P>
        9、Have abundant experience and in-depth understanding of a certain industry, including its business, the
        industrial ecosystem, and its interests and relationships.
      </P>

      <A id="pos20"></A>
      <MasterTitle>Java Developer for Data Platform</MasterTitle>
      <Title>Job Description:</Title>
      <P>1、Develop the function of real-time business computing for the data platform;</P>
      <P>2、Architect and construct the risk management engine for the data platform;</P>
      <P>3、Responsible for the performance tuning of high-concurrency services on the data platform.</P>
      <Title>Job Requirements:</Title>
      <P>1、Full-time Bachelor’s degree or above, in computer or related majors;</P>
      <P>2、Solid foundation in JAVA language programming, with more than 2 years of work experience;</P>
      <P>3、Experience in developing and using stream and batch processing, such as flink and spark;</P>
      <P>4、Familiar with zookeeper, kafka, Hadoop, Spark, and related platforms;</P>
      <P>5、Good communication and teamwork abilities; strong problem analysis and solving skills.</P>

      <A id="pos21"></A>
      <MasterTitle>Data Warehouse Developer</MasterTitle>
      <Title>Job Description:</Title>
      <P>1、Participate in constructing OPay data warehouse;</P>
      <P>2、Optimize the ETL process of the data warehouse and solve related technical problems;</P>
      <P>3、Conduct data modeling for core business, and cube data development;</P>
      <P>4、Optimize OPay data infrastructure;</P>
      <P>
        5、Produce and launch data products to realize the productization of data value; responsible for data governance
        in related fields to ensure stable data output.
      </P>
      <Title>Job Requirements:</Title>
      <P>
        1、In-depth understanding of commonly used data modeling theories, and ability to design different types of data
        warehouse independently;
      </P>
      <P>
        2、Familiar with Hadoop ecosystem, Presto, and Flink, proficient in Hdfs, Hive, Spark, and MR, and have
        experience in task tuning;
      </P>
      <P>
        3、Understand the importance of data governance, have knowledge of and related work experience in data
        governance;
      </P>
      <P>4、Solid background in programming, skilled at Java or Python, and proficient in Linux and Shell.</P>

      <A id="pos22"></A>
      <A id="pos23"></A>
      <A id="pos24"></A>
      <MasterTitle>Senior Java Developer</MasterTitle>
      <Title>Job Description:</Title>
      <P>
        1、Develop systems for independent risk management modules (decision engine, feature calculation, function sets
        management, etc.);
      </P>
      <P>2、Participated in the overall technology selection, design, and research and development of the system;</P>
      <P>3、Responsible for requirements analysis, outline and detailed design, coding and testing of the system;</P>
      <P>
        4、Interested in learning different technologies, and participate in in-house technological exchange and sharing
        sessions to drive the team forward.
      </P>
      <Title>Job Requirements:</Title>
      <P>
        1、Bachelor’s degree or above in computer-related majors; the condition can be appropriately relaxed for
        candidates with excellent technological abilities;
      </P>
      <P>
        2、Solid background in Java with at least 3 years of experience in developing multi-threading and
        high-concurrency systems; familiar with mainstream open source frameworks, such as Spring Boot, Spring MVC, and
        FreeMarker;
      </P>
      <P>
        3、Practical experience in distributed framework development, cache processing, low-level function processing,
        data stream processing, etc.; familiar with analysis technologies such as ES; master Redis in-memory databases
        and data calculation optimization; understand common message queues, such as ActiveMQ and Kafka, and familiar
        with Flink real-time stream processing;
      </P>
      <P>
        4、Proficiency in SQL development and relational databases, such as Mysql and Oracle; ability to independently
        design, develop and maintain the assigned system modules;
      </P>
      <P>
        5、Good coding habits, with a strong sense of responsibility and anti-pressure ability; good abilities of
        logical thinking and independent problem analysis and settlement;
      </P>
      <P>
        6、Experience in developing financial risk management products is preferred; Fluency in English is preferred.
      </P>

      <A id="pos25"></A>
      <MasterTitle>Senior Risk Model Engineer</MasterTitle>
      <Title>Job Description:</Title>
      <P>
        1、Develop scoring models, anti-fraud models, and other related models for risk management based on internal and
        external big data;
      </P>
      <P>
        2、Advance the construction of data warehouses and data marts for risk management, and establish feature
        datasets for risk management;
      </P>
      <P>
        3、Use mathematical statistics or machine learning algorithms to construct data models and establish model
        evaluation standards;
      </P>
      <P>4、Monitor and optimize model performance in time to continuously improve its accuracy;</P>
      <P>5、Establish regular indicators for model operation and optimize the efficiency of model management.</P>
      <Title>Job Requirements:</Title>
      <P>
        1、More than 5 years of risk-related work experience in payment institutions or financial institutions; Master's
        degree or above in economics, finance, mathematics, statistics, computer, and other related majors (candidates
        with Bachelor’s degree must have over 7 years of working experience); Ph.D. is preferred;
      </P>
      <P>2、Data-driven thinking, proficient in SQL and at least one of Python, R, and SAS;</P>
      <P>
        3、Proficient in mathematical statistics or machine learning algorithms, familiar with DT, LR, GBDT, XGboost,
        LGM, etc., understand the principles of algorithm design, and research and optimize algorithms;
      </P>
      <P>
        4、Understand the self-learning mode of machine learning, and be familiar with system docking and deployment
        after the model is developed;
      </P>
      <P>
        5、Good communication and learning ability, proactiveness, and innovative spirit; proficiency in oral and
        written English is preferred.
      </P>

      <A id="pos26"></A>
      <A id="pos27"></A>
      <A id="pos28"></A>
      <MasterTitle>1. WEB Penetration Testing Specialist/Senior Safety Engineer</MasterTitle>
      <Title>Job Description:</Title>
      <P>
        1、Take responsibility for web penetration, safety scanning, system recovery consultation for online business
        and internal system;
      </P>
      <P>2、Conduct safety assessment and rectification for all application system;</P>
      <P>
        3、Track new security vulnerabilities, understand up-to-date hacking and defense technology and solution, carry
        out the safety training;
      </P>
      <Title>Requirements:</Title>
      <P>
        1、Familiar with the general principle of safety vulnerabilities, master or excel at web safety penetration, app
        program vulnerabilities analysis, intrusion and attack testing, Two or more skills of Fuzzing. A deep
        understanding of vulnerabilities logic, penetration strategy, and approach;
      </P>
      <P>2、Proficiency in black-box testing tools like burpsuite, AWVS, appscan;</P>
      <P>3、Experienced in Java and mainstream WEB framework (spring/struts2) and java web structure;</P>
      <P>
        4、Minimum 3 years of experience in web safety tests and identified a number of web or app security
        vulnerabilities with medium and high risk;
      </P>
      <P>5、With experience in penetration testing of mobile apps;</P>
      <P>
        6、Outstanding learning capacity and communication skills, good team spirits, and a strong sense of
        responsibility.
      </P>
      <Title>Desirable qualification:</Title>
      <P>1、Security-related experience in Internet company</P>
      <P>2、Experience in SRC and emergent response to vulnerabilities</P>
      <P>3、Familiar with CVE</P>

      <A id="pos29"></A>
      <MasterTitle>SDL specialist</MasterTitle>
      <Title>Job Description:</Title>
      <P>
        1、Fully participate in the security demand assessment for projects and products, including proposal review,
        threat modeling, safety test and rectification;
      </P>
      <P>
        2、Tune the SDL process, implement the shift left security, build the capacity and tool library for internal
        security test;
      </P>
      <P>
        3、Take the lead in security vulnerability research and security incident emergent response and deliver
        effective solution;
      </P>
      <P>4、Take responsibility for promoting the team and improving the overall security capacity;</P>
      <Title>Job Requirements:</Title>
      <P>
        1、Minimum 3 years of experience in web security test and familiar with SDL/DevSecOps security development
        process;
      </P>
      <P>2、A deep understanding of the principles, causes of owasp top10 and able to deliver correct solutions;</P>
      <P>3、Proficient in black and white box testing tools like burpsuite, AWVS, appscan, Fortify;</P>
      <P>4、Experienced in Java and mainstream WEB framework (spring/struts2) and java web structure;</P>
      <P>
        5、Outstanding learning capacity and communication skills, good team spirits, and a strong sense of
        responsibility.
      </P>
      <P>6、Minimum 3 years of experience in web security </P>

      <A id="pos30"></A>
      <A id="pos31"></A>
      <A id="pos32"></A>
      <MasterTitle>Product Manager</MasterTitle>
      <Title>Job Description:</Title>
      <P>
        1、Be responsible for the overall grasp and work deployment of POS receipt planning and product development
        direction, carry out product development planning in combination with the company's development strategy,
        clarify the positioning, objectives and strategies, and implement them;
      </P>
      <P>
        2、Grasp the product development trend, analyze and track competitors, tap user needs, and regularly complete
        the analysis report or product competition strategy and plan;
      </P>
      <P>
        3、Be responsible for formulating product specifications, comprehensively designing products, and working with
        technicians to make comprehensive use of various technical means to complete product R & D and optimization;
      </P>
      <P>
        4、 Organize internal and external research and analysis of products and operation modes, analyze user data and
        find corresponding improvement schemes to provide core basis for decision-making.
      </P>
      <Title>Job Requirements:</Title>
      <P>
        1、 3 years or above experience. Have their own opinions on the products, and have the desire to make a product
        that can provide users with a comfortable use experience and even change users' products through their own
        efforts;
      </P>
      <P>
        2、Experience in third-party payment, offline bill collection, SaaS cashier and living payment is preferred;
        Have keen market insight and a wide range of knowledge;{" "}
      </P>
      <P>
        3、Have working experience in product preliminary analysis and market positioning, be able to independently
        complete product function and data effect analysis and put forward corresponding product optimization
        suggestions;{" "}
      </P>
      <P>4、Have owner awareness and be able to actively promote the work forward and form a closed loop; </P>
      <P>5、Fluent English listening, speaking, reading and writing is a plus.</P>

      <A id="pos33"></A>
      <A id="pos34"></A>
      <A id="pos35"></A>
      <MasterTitle>Payment channel development expert</MasterTitle>
      <Title>Job Description:</Title>
      <P>
        1、 Responsible for solving key technical problems, system optimization, code quality management, participating
        in the design and preliminar y development of implementation scheme, sorting out general technology and
        improving technology reuse;
      </P>
      <P>
        2、Be responsible for product analysis, design and docking with banks, third parties and other institutions to
        ensure project quality and progress;
      </P>
      <P>
        3、 Be responsible for positioning and solving difficult technical problems, customized development, project
        implementation and technical problem support;
      </P>
      <P>
        4、 Participate in the formulation of development specifications and technical standards, and prepare
        corresponding technical, test, design, requirements and other specification documents;
      </P>
      <Title>Job Requirements:</Title>
      <P>
        1、 Have experience in bank channel product docking and development, and understand the process of payment
        products at home and abroad;
      </P>
      <P>
        2、Have experience in leading the team, and be able to coordinate and lead the team to connect the channel in
        time and quickly;
      </P>
      <P>
        3、Solid Java programming skills and good coding standards, and proficient in J2EE related development
        technologies;
      </P>
      <P>
        4、Proficient in Java MVC development architecture mode, familiar with database, profound SQL skills and rich
        database design experience are preferred;
      </P>
      <P>5、Have the ability to learn, bear pressure, and independently analyze and solve problems;</P>
      <P>
        6、Have certain document writing ability, and be familiar with the preparation of management, planning, design
        and other related documents;
      </P>
      <P>
        7、Have strong communication and coordination skills, be brave in technological innovation and innovation, and
        have teamwork and professionalism; Have a strong sense of responsibility and dedication.
      </P>
      <P>
        8、Familiar with distributed technology and communication protocol, such as WebService, restful, spring cloud
        and other technologies is preferred;
      </P>
      <P>
        9、Clear and agile thinking, strong logical analysis ability, high sense of responsibility and strong
        adaptability, and good team spirit;
      </P>
      <P>10、Practical working experience in big data and high concurrency is preferred.</P>
    </Container>
  );
};

Info.defaultProps = {
  position: "",
};

Info.propTypes = {
  position: PropTypes.string,
};

export default Info;
